import { createSlice, current } from '@reduxjs/toolkit';
import { noAuthRoutesCode } from "../../config/routes";

export const slice = createSlice({
	name: 'auth',
	initialState: {
		permissionsRoutes: [],
		isLogin: false,
		menuCode: [...noAuthRoutesCode],
		buttonCode: []
	},
	reducers: {
		setAllRoutes: (state, { payload }) => {
			state.permissionsRoutes = payload
		},
		setMenuArray: (state, action) => {
			const { payload } = action
			state.menuCode = payload
			return state
		},
		setButtonArray: (state, action) => {
			const { payload } = action
			state.buttonCode = payload
			return state
		},
		setLogin: (state, action) => {
			const { payload } = action
			state.isLogin = payload
			return state
		},
	}
});


//dispatch action
export const { setAllRoutes, setMenuArray, setButtonArray, setLogin } = slice.actions

//get userInfo
export const selectRoutes = (state) => state.auth.permissionsRoutes
export const getMenuCode = (state) => state.auth.menuCode
export const getBtnCode = (state) => state.auth.buttonCode
export const getLoginStatus = (state) => state.auth.isLogin

export default slice.reducer;
