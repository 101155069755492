import { createSlice, current } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'user',
	initialState: {
		userInfo: {}
	},
	reducers: {
		setUser: (state, action) => {
			const { payload } = action
			state.userInfo = payload
			return state
		}
	}
});

//dispatch action
export const { setUser } = slice.actions

//get userInfo
export const getUserInfo = (state) => state.user.userInfo

export default slice.reducer;
