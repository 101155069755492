import React from 'react';
import permissionIcon from 'src/img/menu/permission.png';
import noticeIcon from 'src/img/menu/notice.png';
import verifyIcon from 'src/img/menu/verify.png';
import sysIcon from 'src/img/menu/sys.png';
import logIcon from 'src/img/menu/log.png'
import platIcon from 'src/img/menu/platform.png'
import baobiao from 'src/img/menu/baobiao.png'
import qudao from 'src/img/menu/qudao.png'
import shanghu from 'src/img/menu/shanghu.png'
import canshu from 'src/img/menu/canshu.png'
import yinhang from 'src/img/menu/yhcp.png'
import gongzuotai from 'src/img/menu/workForm.png'

const iconSty = { width: 16, height: 16, marginLeft: -6 };

export const noAuthRoutesCode = ['menu-demo', 'menu-login', 'menu-404', 'menu-211', 'menu-root', 'menu-empty']

const routes = [
	{
		path: '/admin/login',
		component: () => import('src/pages/login/index'),
		code: 'menu-login',
	},
	{
		path: '/error/404',
		component: () => import('src/pages/error/page404'),
		code: 'menu-404',
	},
	{
		path: '/error/211',
		component: () => import('src/pages/error/page211'),
		code: 'menu-211',
	},
	{
		path: '/admin/empty',
		component: () => import('src/pages/loginAfter'),
		code: 'menu-empty',
	},
	{
			path: '/',
			component: () => import('../layouts/basicLayout'),
		  code: 'menu-root',
			routes: [
				{
					path: '/home',
					name: '工作台',
					icon: (<span><img src={gongzuotai} style={iconSty}/></span>),
					code: 'menu-home',
					component: () => import('../pages/home'),
				},
				{
					path: '/permissions',
					name: '权限管理',
					icon: (
						<span>
							<img src={permissionIcon} style={iconSty}/>
						</span>
					),
					component: () => import('../layouts/blankLayout'),
					code: 'menu-permissions',
					routes: [
						{
							path: '/permissions/department',
							name: '机构管理',
							component: () => import('../pages/permissions/department'),
							code: 'menu-permissions-department',
						},
						{
							path: '/permissions/role',
							name: '角色管理',
							component: () => import('../pages/permissions/role'),
							code: 'menu-permissions-role',
						},
						{
							path: '/permissions/user',
							name: '用户管理',
							component: () => import('../pages/permissions/user'),
							code: 'menu-permissions-user',
						},
						// {
						// 	path: '/permissions/resource',
						// 	name: '资源管理',
						// 	component: () => import('../pages/permissions/resource'),
						// 	code: 'menu-permissions-resource',
						// },
					]
				},
				{
					path: '/enterprise',
					name: '客户管理',
					icon: (
						<span>
							<img src={qudao} style={iconSty}/>
						</span>
					),
					code: 'menu-enterprise',
					component: () => import('../layouts/blankLayout'),
					routes: [
						{
							path: '/enterprise/list',
							name: '经营主体信息',
							component: () => import('../pages/enterprise/list'),
							code: 'menu-enterprise-enterprise',
						},
						{
							path: '/enterprise/audit',
							name: '经营主体审核',
							component: () => import('../pages/enterprise/auditList'),
							code: 'menu-enterprise-enterpriseAudit',
						},
						{
							path: '/enterprise/detail',
							hideInSideBar: true,
							component: () => import('../pages/enterprise/detail'),
							code: 'menu-enterprise-enterprise-detail',
						}
					]
				},
				{
					path: '/trader',
					name: '商户管理',
					code: 'menu-merchant',
					icon: (
						<span>
							<img src={shanghu} style={iconSty}/>
						</span>
					),
					component: () => import('../layouts/blankLayout'),
					routes: [
						{
							path: '/trader/list',
							name: '商户信息管理',
							code: 'menu-merchant-merchant',
							component: () => import('../pages/trader'),
						},
						{
							path: '/trader/appId',
							name: 'APPID信息管理',
							code: 'menu-merchant-appId',
							component: () => import('../pages/appId'),
						},
						{
							path: '/trader/audit',
							name: '商户信息审核',
							code: 'menu-merchant-merchantAudit',
							component: () => import('../pages/trader/auditList'),
						},
						{
							path: '/trader/detail',
							hideInSideBar: true,
							code: 'menu-merchant-detail',
							component: () => import('../pages/trader/detail'),
						},
					]
				},
				{
					path: '/product',
					name: '银行产品管理',
					code: 'menu-product',
					icon: (
						<span>
							<img src={yinhang} style={iconSty}/>
						</span>
					),
					component: () => import('../layouts/blankLayout'),
					routes: [
						{
							path: '/product/ipps',
							name: '中行IPPS产品',
							code: 'menu-product-ipps',
							component: () => import('../pages/ipps'),
						},
						{
							path: '/product/dcss',
							name: '中行DCSS产品',
							code: 'menu-product-dcss',
							component: () => import('../pages/dcss'),
						},
						{
							path: '/product/ippsdetail',
							name: 'ipps详情',
							hideInSideBar: true,
							code: 'menu-product-ippsdetail',
							component: () => import('../pages/ipps/detail'),
						},
						{
							path: '/product/dcssdetail',
							name: 'dcss详情',
							hideInSideBar: true,
							code: 'menu-product-dcssdetail',
							component: () => import('../pages/dcss/detail'),
						},
					]
				},
				{
					path: '/parameter',
					name: '业务参数管理',
					code: 'menu-parameter',
					icon: (
						<span>
							<img src={canshu} style={iconSty}/>
						</span>
					),
					component: () => import('../layouts/blankLayout'),
					routes: [
						// {
						// 	path: '/parameter/mcc',
						// 	name: 'MCC信息管理',
						// 	code: 'menu-parameter-mcc',
						// 	component: () => import('../pages/mcc'),
						// },
						{
							path: '/parameter/category',
							name: '经营类别管理',
							code: 'menu-parameter-category',
							component: () => import('../pages/category'),
						},
						// {
						// 	path: '/parameter/dealChannel',
						// 	name: '交易渠道管理',
						// 	code: 'menu-parameter-dealChannel',
						// 	component: () => import('../pages/dealChannel'),
						// },
						// {
						// 	path: '/parameter/dealType',
						// 	name: '交易类型管理',
						// 	code: 'menu-parameter-dealType',
						// 	component: () => import('../pages/dealType'),
						// },
						// {
						// 	path: '/parameter/bank',
						// 	name: '运营机构列表',
						// 	code: 'menu-parameter-bank',
						// 	component: () => import('../pages/bank'),
						// },
						// {
						// 	path: '/parameter/channel',
						// 	name: '运营渠道列表',
						// 	code: 'menu-parameter-channel',
						// 	component: () => import('../pages/bankChannel'),
						// },
					]
				},
				{
					path: '/system',
					name: '系统运维管理',
					icon: (
						<span>
							<img src={sysIcon} style={iconSty}/>
						</span>
					),
					code: 'menu-system',
					component: () => import('../layouts/blankLayout'),
					routes: [
						// {
						// 	path: '/system/sysdicts',
						// 	name: '字典管理',
						// 	component: () => import('../pages/sysdicts/list'),
						// 	code: 'menu-system-sysdicts',
						// },
						{
							path: '/system/log',
							name: '日志管理',
							component: () => import('../pages/log'),
							code: 'menu-system-log',
						},
						// {
						// 	path: '/system/check',
						// 	name: '对账差错管理',
						// 	component: () => import('../pages/checkBill'),
						// 	code: 'menu-system-check',
						// },
						// {
						// 	path: '/system/fix',
						// 	name: '调账信息管理',
						// 	component: () => import('../pages/fixBill'),
						// 	code: 'menu-system-fix',
						// },
						{
							path: '/system/cache',
							name: '缓存管理',
							component: () => import('../pages/cache'),
							code: 'menu-system-cache',
						},

					]
				},
				{
					path: '/sheet',
					name: '报表管理',
					icon: (
						<span>
							<img src={baobiao} style={iconSty}/>
						</span>
					),
					code: 'menu-sheet',
					component: () => import('../layouts/blankLayout'),
					routes: [
						{
							path: '/sheet/transaction',
							name: '交易流水报表',
							component: () => import('../pages/sheet/transaction'),
							code: 'menu-sheet-transaction',
						},
						{
							path: '/sheet/detail',
							name: '交易流水详情',
							hideInSideBar: true,
							component: () => import('../pages/sheet/transaction/detail'),
							code: 'menu-sheet-detail',
						},
						{
							path: '/sheet/refund',
							name: '退货流水报表',
							component: () => import('../pages/sheet/refund'),
							code: 'menu-sheet-refund',
						},
						{
							path: '/sheet/refundDetail',
							name: '退货流水详情',
							hideInSideBar: true,
							component: () => import('../pages/sheet/refund/detail'),
							code: 'menu-sheet-refundDetail',
						},
					]
				},

			]
	}
];


function addLazyComponent(routes) {
	routes.forEach(route => {
		route.component = React.lazy(route.component);
		if (route.routes) {
			addLazyComponent(route.routes);
		}
	});
}

addLazyComponent(routes);

export default routes
