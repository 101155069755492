/*
* 判断是否是移动端
* */
export function isMobile() {
	const agent = navigator.userAgent;
	const k = ["android", "iphone", "ipod", "ipad", "windows phone", "mqqbrowser"];
	let flag = false;
	if (agent.indexOf("Windows NT") < 0 || (agent.indexOf("Windows NT") >= 0 && agent.indexOf("compatible; MSIE 9.0;") >= 0)) {
		if (agent.indexOf("Windows NT") < 0 && agent.indexOf("Macintosh") < 0) {
			for (let item of k) {
				if (agent.indexOf(item) >= 0) {
					flag = true;
					break;
				}
			}
		}
	}
	return flag;
}

/*
* 判断是否是PC端
* */
export function IsPC() {
	let userAgentInfo = navigator.userAgent;
	let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
	let flagPc = true;
	for (let v = 0; v < Agents.length; v++) {
		if (userAgentInfo.indexOf(Agents[v]) > 0) {
			flagPc = false;
			break;
		}
	}
	return flagPc;
}

/*
* 防抖
* */
export function debounce(fn, delay) {
	delay = delay || 1000;
	let timer = null;
	return function () {
		let context = this;
		let arg = arguments;
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			fn.apply(context, arg);
		}, delay);
	};
}

/*
* 节流
* */
export function throttle(fn, delay = 300) {
	let timer = null;
	return function () {
		let context = this;
		let args = arguments;
		if (!timer) {
			timer = setTimeout(function () {
				fn.apply(context, args);
				clearTimeout(timer);
			}, delay);
		}
	};
}

export const deepClone = (obj) => {
	//判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
	var objClone = Array.isArray(obj) ? [] : {};
	//进行深拷贝的不能为空，并且是对象或者是
	if (obj && typeof obj === "object") {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				if (obj[key] && typeof obj[key] === "object") {
					objClone[key] = deepClone(obj[key]);
				} else {
					objClone[key] = obj[key];
				}
			}
		}
	}
	return objClone;
}

/*
* 手机号脱敏，such: 139****0000
* */
export function hideMobile(mobileNumber) {
	if(!mobileNumber) return '--'
	return mobileNumber.replace(/^(\d{3})\d{4}(\d{4})$/, "$1 **** $2")
}

export const filterRoutes = (pathCode, routes) => {
	return routes.filter((item) => {
		return pathCode.indexOf(item.code) !== -1
	}).map((item) => {
		if(item.routes) {
			return {
				...item,
				routes: filterRoutes(pathCode, item.routes)
			}
		}
		return item
	})
}

//复制内容到剪切板
export const currentCopy = value => {
	const input = document.createElement('input');
	document.body.appendChild(input);
	input.style.opacity = 0;
	input.value = value;
	input.focus();
	input.setSelectionRange(0, input.value.length);
	const res = document.execCommand('copy', true);
	document.body.removeChild(input);
	return res;
};

export const funDownload = (content, filename) => {
	let eleLink = document.createElement('a');
	eleLink.target = '_blank';
	eleLink.download = filename;
	eleLink.style.display = 'none';
	let blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' });
	eleLink.href = URL.createObjectURL(blob);
	document.body.appendChild(eleLink);
	eleLink.click();
	document.body.removeChild(eleLink);
};

export function generateCode(){
	let code = '';
	for(let i=0; i<4; i++){
		code += Math.floor(Math.random() * 10);
	}
	return code;
}
