class LocalInstance {
	tokenKey = 'user_token';
	store = window.localStorage;
	menuKey = 'role_menu';
	btnKey = 'role_button';

	getToken() {
		return this.store && this.store.getItem(this.tokenKey);
	}
	setToken(token) {
		this.store && this.store.setItem(this.tokenKey, token);
	}
	removeToken() {
		this.store && this.store.removeItem(this.tokenKey);
	}

	getMenu() {
		return this.store && this.store.getItem(this.menuKey);
	}
	setMenu(menu) {
		this.store && this.store.setItem(this.menuKey, menu);
	}
	removeMenu() {
		this.store && this.store.removeItem(this.menuKey);
	}

	getBtn() {
		return this.store && this.store.getItem(this.btnKey);
	}
	setBtn(btn) {
		this.store && this.store.setItem(this.btnKey, btn);
	}
	removeBtn() {
		this.store && this.store.removeItem(this.btnKey);
	}


}
export default new LocalInstance();
