import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loading from '../loading'

const Routes = props => {
	return (
		<Switch>
			{props.routes.map(
				({ component: Component, ...route }, index) => {
					return (
						<Route
							path={route.path}
							key={index}
							{...route}
							render={routeProps => {
								return (
									<Suspense fallback={<Loading />}>
										<Component {...route} {...routeProps}>
											<Routes routes={route.routes} />
										</Component>
									</Suspense>
								);
							}}
						/>
					);
				}
			)}
			{/*<Redirect to="/error/404" />*/}
		</Switch>
	);
};

export default React.memo(Routes);
