import HTTP from '../config/http';

class Auth extends HTTP {
	// 负责区域
	async getSource() {
		return await this.get('/test')
	}

	// 获取菜单权限
	async getMenuSource(params) {
		return await this.post('/platform/auth/queryAuthMenus', params)
	}

	//获取按钮权限
	async getButtonSource(params) {
		return await this.post('/platform/auth/queryAuthButtons', params)
	}

}
export default new Auth()
