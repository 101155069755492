import axios from 'axios';
import { message } from 'antd'
import config from '../env/env.dev';
import history from '../../libs/history';
import userLocal from 'src/libs/localInstance'

//TODO timeout is too large
const axiosInstance = axios.create({
	baseURL: config.API_URL,
	timeout: 60000
});

const StatusCode = {
	success: 200,
	unauthorized: 401
}

const needSetBlobType = [
	'/file/v1/ossDownload',
	'/platform/order/exportPayOrderList',
	'/platform/order/exportPayRefundOrderList'
]

const fileToJson = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = res => {
			const { result } = res.target
			let data
			try {
				data = JSON.parse(result)
			} catch (e) {
				data = result
			}
			resolve(data)
		};
		reader.onerror = err => {
			reject(err)
		};
		reader.readAsText(new Blob([file]), 'utf-8')
	})
}

axiosInstance.interceptors.request.use(
	function (config) {
		const token = userLocal.getToken()
		if (token) {
			config.headers['tokenId'] = token;
		}
		if (needSetBlobType.indexOf(config.url) !== -1) {
			config.responseType = 'blob';
		}
		return config
	},
	function (error) {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	async function (response) {
		if (response.status === StatusCode.success) {
			let { data, config, headers } = response;
			if(config.responseType === 'blob') {
				return data
			}
			const { code, errorMsg, message: msg } = data
			if(code !== 200) {
				if(msg && msg?.indexOf('Data too long for column') !== -1) {
					message.error('存在输入过长情况，请重新输入')
				} else {
					message.error(errorMsg || '系统异常')
				}
			}
			return data
		}
		return response
	},
	function (error) {
		const { response } = error
		const { status } = response || {}
		if(status === StatusCode.unauthorized) {
			history.push('/admin/login')
		} else {
			message.error('系统异常')
		}
		return {
			success: false,
			error: error
		};
	}
);
export default axiosInstance;
