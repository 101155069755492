import React from 'react'

const Index = () => {
	return (
		<div>
			<div/>
		</div>
	)
}

export default Index
