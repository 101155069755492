import HTTP from '../config/http';

class User extends HTTP {

	async getUserInfo() {
		return await this.post('/platform/user/personalInfo')
	}

	async createUser(params) {
		return await this.post('/platform/user/create', params)
	}

	async updateUser(params) {
		return await this.post('/platform/user/update', params)
	}

	async userList(params) {
		return await this.post('/platform/user/page', params)
	}

	async userDisable(params) {
		return await this.post('/platform/user/disable', params)
	}

	async userEnable(params) {
		return await this.post('/platform/user/enable', params)
	}

	async deleteUser(params) {
		return await this.post('/platform/user/delete', params)
	}

	async resetUserPwd(params) {
		return await this.post('/platform/user/resetPassword', params)
	}
}

export default new User()
