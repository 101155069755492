import React, {useEffect, useState} from "react";
import './App.css';
import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'
import { Router } from 'react-router-dom';
import history from './libs/history';
import Routes from './components/routes';
import routes from 'src/config/routes'
import { useSelector, useDispatch } from 'react-redux';
import {getMenuCode, setMenuArray, setButtonArray, getLoginStatus} from "src/store/reducer/auth";
import { setUser } from 'src/store/reducer/user'
import { filterRoutes } from "./config/utils";
import { noAuthRoutesCode } from "src/config/routes";
import authService from "./services/auth";
import userService from "./services/user";

function App() {

	const codeArr = useSelector(getMenuCode)
	const loginStatus = useSelector(getLoginStatus)
	const tempRoutes = cloneDeep(routes)
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false)

	let authRoutes = filterRoutes(codeArr, tempRoutes) //权限路由

	const getMenu = () => {
		setLoading(true)
		authService.getMenuSource().then((res) => {
			const { code, data } = res
			if(code === 200) {
				let codes = data.codes || []
				if(codes.length === 0) {
					history.replace('/error/211')
					return
				}
				dispatch(setMenuArray([...noAuthRoutesCode, ...codes]));
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	const getBtn = () => {
		setLoading(true)
		authService.getButtonSource().then((res) => {
			const { code, data } = res
			if(code === 200) {
				let codes = data.codes || []
				dispatch(setButtonArray([...codes]));
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	const getUserInfo = () => {
		userService.getUserInfo().then((res) => {
			if(res.code === 200) {
				dispatch(setUser(res.data))
			}
		})
	}

	useEffect(() => {
		const hash = window.location.hash
		const noNeedAuth = [
			'#/admin/login',
			'#/admin/demo',
			'#/error/404',
			'#/error/211'
		]
		if(loginStatus || noNeedAuth.indexOf(hash) === -1) {
			getMenu()
			getBtn()
			getUserInfo()
		}
	}, [loginStatus, location.hash])

	useEffect(() => {
		if(location.hash === '#/') {
			history.push('/home')
		}
	}, [location.hash])


  return (
	  <div>
		  {loading ? <Skeleton/> :
			  (
				  <Router history={history}>
					  {/*<Routes routes={authRoutes} />*/}
					  <Routes routes={routes} />
				  </Router>
			  )
		  }
		</div>
  );
}

export default App;
