import { AxiosRequestConfig } from 'axios';
import axios from './axios';
// HTTP 只处理 status是200的响应，res是response data

const defaultResponse = {
	code: -999,
	message: 'init response',
	result: null
}
class HTTP {
	config =  {
		baseURL: axios.defaults.baseURL
	};

	getConfig(config) {
	  return Object.assign({}, this.config, config || {});
	}

	async get(url, config) {
		try {
			const res = await axios.get(url, this.getConfig(config));
			return res;
		} catch (e) {
			console.log(e);
		}
		return defaultResponse;
	}

  async post(url, data, config) {
		try {
			const res = await axios.post(
				url,
				data,
				this.getConfig(config)
			);
			return res;
		} catch (e) {
			console.log(e);
		}
		return defaultResponse;
	}
}
export default HTTP;
