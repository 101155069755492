import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import zhCN from 'antd/es/locale/zh_CN';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { ConfigProvider } from 'antd'
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<ConfigProvider
			locale={zhCN}
			theme={{
				token: {colorPrimary: '#1890ff'}
			}}
		>
			<App />
		</ConfigProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
